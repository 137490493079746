<script>
import RsAttachment from "@/components/modules/rightship/RsAttachment.vue";
import ObjectMixin from "@/mixins/ObjectMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import AttachmentMixin from "@/mixins/attachmentMixin";

export default {
  name: "RsInspection",
  mixins: [ObjectMixin, AttachmentMixin, permissionMixin],
  components: {RsAttachment},
  data() {
    return {
      toEdit: false,
      toEditQ: false,
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      default: {
        mainReport: {
          id: parseInt(this.$route.params.id),
          inspection: {
            inspector_name: null,
            non_conformities: false,
            detained: false,
            non_conformity_count: 0,
            outline: null,
          },
        },
      },
      mainReport: {},
      questionAnswers: {},
      rankIdMaster: 1,
      rankIdCE: 2,
      crewLists: {},
      addAttachment: false,
    }
  },
  methods: {
    ...mapActions(['updateRsInfo', 'uploadRsAttachments']),
    async cancelUpdate() {
      if (await AlertService.cancelAlert()) {
        this.resetFields()
      }
    },
    async updateRs() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updateRsInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('Rightship updated successfully', 'UPDATE RIGHTSHIP')
          this.resetFields()
        }
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = {
        id: this.rsGet('id'),
        inspection: {
          inspector_name: this.rsGet('inspection.inspector_name'),
          non_conformities: !!this.rsGet('inspection.non_conformities', false),
          detained: !!this.rsGet('inspection.detained'),
          non_conformity_count: this.rsGet('inspection.non_conformity_count'),
          outline: this.rsGet('inspection.outline'),
        }
      }
    },
    resetFields() {
      this.toEdit = false
      this.mainReport = this.default.mainReport
    },

    async addAttachmentToInspection() {
      let formData = new FormData();

      if (this.$refs.inspectionAttachments.files.length) {
        Array.prototype.forEach.call(this.$refs.inspectionAttachments.files, file => {
          formData.append('attachments[]', file)
        })
      }
      formData.append('id', this.rsGet('id'))
      formData.append('type[id]', this.rsGet('inspection.id'))
      formData.append('type[type_id]', 1)
      const response = await this.uploadRsAttachments(formData);
      if (response) {
        AlertService.successAlert('Attachment added successfully', 'Add Inspection Attachments')
        this.addAttachment = false;
        await this.getRsAttachment(1, this.rsReport, 'inspection.id')
      }
    },

    async cancelUpload() {
      if (await AlertService.cancelAlert()) {
        this.$refs.inspectionAttachments.value = null;
        this.addAttachment = false;
      }
    },

    async deleteInspectionAttachments(attachment) {
      const file = attachment.attachment
      const type = attachment.type
      const response = await this.deleteFileRs(file, type)
      if (response) {
        AlertService.successAlert('Rightship Inspection Attachment deleted successfully', 'DELETE INSPECTION ATTACHMENT')
        await this.getRsAttachment(type, this.rsReport, 'inspection.id')
      }
    },

    getAnswer(questionAnswer, default_value = null) {
      let value = null;

      switch (questionAnswer?.rs_question?.rs_question_type?.description) {
        case 'text':
          value = questionAnswer.answer;
          break;
        case 'options':
          value = questionAnswer.answer_value ? parseInt(questionAnswer.answer_value) : 0;
          let option = questionAnswer.rs_question.rs_dropdown_options.filter(option => option.id == value);
          option = option.length ? option[0] : {};
          value = Object.keys(option).length ? option.value : null;
          break;
        case 'boolean':
          value = Boolean(questionAnswer.answer) ? 'Yes' : 'No';
          break;
        case 'boolean-text':
          value = Boolean(questionAnswer.answer_value) ? ('Yes: ' + questionAnswer.answer) : 'No';
          break;

        case 'integer':
          // @TODO: create new question type 'float'...
          value = `${!isNaN(questionAnswer.answer) && questionAnswer.answer ? parseFloat(questionAnswer.answer) : '0'}
                   ${questionAnswer.rs_question.rs_question_type.id == 5 ? "hrs" : ''}`
          break;
      }

      if (value != null) {
        value = String(value).toUpperCase();
      }

      return value ?? default_value;
    },
    getQuestionnaire() {
      this.questionAnswers = JSON.parse(JSON.stringify(this.rsGet('question_answers', {})));
    },
    editQuestionnaire() {
      this.toEditQ = true;
      this.getQuestionnaire();
    },
    resetQuestionnaireFields() {
      this.toEditQ = false;
      this.getQuestionnaire();
    },
    async submitQuestionnaire() {
      if (await AlertService.confirmUpdateAlert("Do you want to update?")) {
        const params = {
          __return_response: true,
          id: this.rsGet('id'),
          question_answers: this.questionAnswers,
        };

        const response = await this.updateRsInfo(params);

        if (response.status === 200) {
          AlertService.successAlert('Questionnaire has been updated successfully', 'UPDATE RIGHTSHIP');
          this.resetQuestionnaireFields();
        }
      }
    },
    updateQuestionAnswer(index, type = 'text') {
      let answer = this.questionAnswers[index].answer;
      let answer_value = this.questionAnswers[index].answer_value;

      switch (type) {
        case 'boolean':
        case 'options':
          this.questionAnswers[index].answer = answer_value ? answer_value : null;
          break;
        case 'boolean-text':
          this.questionAnswers[index].answer = answer_value ? answer : null;
          break;
      }
    },
    async cancelEditQuestionAnswer() {
      if (await AlertService.cancelAlert()) {
        this.resetQuestionnaireFields();
      }
    },
  },
  async created() {
    await this.getQuestionnaire();

  },
  computed: {
    ...mapGetters([
      'rsReport',
      'rsAttachments',
    ]),
  },
}
</script>
<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Inspection
            </h5>
          </div>
          <div class="col-auto">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="button" @click="updateRs">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport">Edit Inspection Details</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Name of Inspector</td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.inspection.inspector_name" class="form-control-sm form-control"
                     name="inspection_inspector_name" id="inspection_inspector_name">
              <span v-else>{{ rsGet('inspection.inspector_name') | toUpper }}</span>
            </td>
          </tr>
          <tr>
            <td>Number of Findings</td>
            <td>
              <input type="number" :step="0" :min="0" v-if="toEdit" v-model="mainReport.inspection.non_conformity_count"
                     class="form-control-sm form-control" name="rs_non_conformity_count" id="rs_non_conformity_count">
              <span v-else>{{ rsGet('non_conformity_count', 0) }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%" class="align-top">Inspection Outline</td>
            <td>
              <vue-editor v-if="toEdit" v-model="mainReport.inspection.outline" :editor-toolbar="toolbar"></vue-editor>
              <div v-else id="textArea" class="pre-wrap-txt textarea-display-card my-2" v-html="rsGet('inspection.outline')"/>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Completion of the list of non-conformities and inspection report</td>
            <td>
              <template v-if="toEdit">
                <template v-if="!addAttachment">
                  <button class="e-btn e-btn-green mr-3" @click="addAttachment=!addAttachment"
                          v-if="(isElite() || (isAllowed('rs.main.inspection.attachments.create') && isOwnVessel('rsReport'))) && rsAttachments.length <=1">
                    Add Attachment
                  </button>
                  <button class="e-btn e-btn-blue" @click="downloadAllRs(1,rsReport,'inspection.id')"
                          v-if="rsAttachments.length && (isElite() || isAllowed('rs.main.inspection.attachments.download-all') && isOwnVessel('rsReport'))">
                    Download All
                  </button>
                </template>
                <div v-else>
                  <input type="file" class="w-75" ref="inspectionAttachments" multiple @change="addAttachmentToInspection">
                  <button class="e-btn e-btn-outlined-red" @click="cancelUpload">Cancel</button>
                </div>
              </template>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <rs-attachment v-if="rsAttachments.length" :attachments="rsAttachments"
                             style="min-height: 15vh;"
                             :type="1"
                             view-permission="rs.main.inspection.attachments.view-download"
                             download-permssion="rs.main.inspection.attachments.view-download"
                             delete-permission="rs.main.inspection.attachments.delete"
                             @deleteEmitter="deleteInspectionAttachments"
              >
              </rs-attachment>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr/>
            </td>
          </tr>
        </table>
      </form>
      <form @submit.prevent="submitQuestionnaire">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Questionnaire
            </h5>
          </div>
          <div class="col-auto" v-if="isElite() || (isAllowed('rs.main.questionnaire.edit') && isOwnVessel('rsReport'))">
            <button class="e-btn e-btn-green ml-2" type="button" v-if="!toEditQ" @click="editQuestionnaire">Edit Questionnaire</button>
            <template v-else>
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelEditQuestionAnswer">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <template v-if="Object.keys(questionAnswers).length">
            <tr v-for="(questionAnswer, index) in questionAnswers">
              <td style="width: 50%; vertical-align: top;">{{ questionAnswer.rs_question.description }}</td>
              <td>
                <template v-if="toEditQ">
                  <template v-if="questionAnswer.rs_question.rs_question_type.description === 'text'">
                    <input class="form-control-sm form-control mb-1" type="text" v-model="questionAnswers[index].answer">
                  </template>
                  <template v-if="questionAnswer.rs_question.rs_question_type.description === 'integer'">
                    <div class="row ml-0 align-items-center">
                      <input class="form-control-sm form-control mb-1 col-3" type="text" v-model="questionAnswers[index].answer">
                      <span class="font-weight-bold pl-2">{{ questionAnswer.rs_question.rs_question_type.id == 5 ? 'Hrs' : '' }}</span>
                    </div>
                  </template>
                  <template v-if="questionAnswer.rs_question.rs_question_type.description === 'boolean'">
                    <select class="form-control form-control-sm mb-1" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                            @change="updateQuestionAnswer(index, questionAnswer.rs_question.rs_question_type.description)">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </template>
                  <template v-if="questionAnswer.rs_question.rs_question_type.description === 'boolean-text'">
                    <div class="form-row mb-1 p-1">
                      <select class="form-control form-control-sm col-3" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                              @change="updateQuestionAnswer(index, questionAnswer.rs_question.rs_question_type.description)">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>

                      <template v-if="!!questionAnswers[index].answer_value">
                        <input class="form-control form-control-sm col-9" type="text" v-model="questionAnswers[index].answer">
                      </template>
                    </div>
                  </template>
                  <template v-if="questionAnswer.rs_question.rs_question_type.description === 'options'">
                    <div class="form-row mb-1 p-1">
                      <select class="form-control form-control-sm col-3" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                              @change="updateQuestionAnswer(index, questionAnswer.rs_question.rs_question_type.description)">
                        <option value="0" :disabled="questionAnswers[index].answer_value == 0">Select One</option>
                        <template v-for="option in questionAnswer.rs_question.rs_dropdown_options">
                          <option :value="option.id" :disabled="option.id == questionAnswers[index].answer_value">{{ option.value }}</option>
                        </template>
                      </select>
                    </div>
                  </template>
                </template>
                <template v-else>
                  {{ getAnswer(questionAnswer, 'N/A') }}
                </template>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="2" class="text-center">
              No Questionnaire Found.
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<style scoped>
table td {
  padding: 2px !important;
}
</style>
