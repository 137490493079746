<template>
  <div :style="$attrs.style" class="overflow-auto w-100 mt-3">
    <div class="row py-type no-gutters mb-1" v-for="attachment in attachments">
      <div class="col-5">
        {{ attachment.name }}.{{ attachment.extension }}
      </div>
      <div class="col-2">
        {{ attachment.created_at }}
      </div>
      <div class="col-2">
        {{ attachment.file_size }}
      </div>
      <div class="col-3">
        <template v-if="attachment.mime_type.includes('image')">
          <button class="btn btn-secondary btn-xs mr-2"
                  type="button"
                  v-if="isElite() || (isAllowed(viewPermission) && isOwnVessel('rsReport'))"
                  @click="viewRsDownloadFile(attachment,type)"
                  :disabled="!attachment.has_file">
            View
          </button>
          <button class="btn btn-secondary btn-xs mr-2"
                  type="button"
                  v-if="isElite() || (isAllowed(downloadPermssion) && isOwnVessel('rsReport'))"
                  @click="viewRsDownloadFile(attachment,type,'download')"
                  :disabled="!attachment.has_file">
            Download
          </button>
        </template>

        <template v-else>
          <button
            type="button"
            class="btn btn-secondary btn-xs mr-2" :disabled="!attachment.has_file"
            @click="viewRsDownloadFile(attachment,type)"
            v-if="isElite() || (isAllowed(viewPermission) && isOwnVessel('rsReport'))"
          >
            View / Download
          </button>
        </template>
        <button class="btn btn-danger btn-xs"
                type="button"
                @click="$emit('deleteEmitter',{attachment:attachment,type:type})"
                v-if="isElite() || (isAllowed(deletePermission) && isOwnVessel('rsReport'))"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentMixin from "../../../mixins/attachmentMixin";
import {permissionMixin} from "../../../mixins/permissionMixins";

export default {
  mixins: [AttachmentMixin, permissionMixin],
  props: {
    type: {
      type: Number,
      default: null,
    },
    viewPermission: {
      type: String,
      default: '',
    },
    downloadPermssion: {
      type: String,
      default: '',
    },
    deletePermission: {
      type: String,
      default: ''
    },
    attachments: {
      type: [Object, Array],
      default: null,
    },
  },

}
</script>

<style scoped>

</style>
